body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Input Style to remove autofill background color  */
input:-webkit-autofill { 
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
}


/* Footer Style */
.footer-list a{
  position: relative;
  left: -20px;
  font-size: 15px;
}
.footer-list .text{
  position: relative;
  left: -6px;
  font-size: 15px;
}
.custom-input {
  border-radius: 15px;
  border: 1px #0c8af8 solid;
  padding: 4px 12px;
  background-color: white;
  height: 22px;
  box-shadow: 0 0 2px #0074d9;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #0c8af8;
  box-shadow: 0 0 10px 2px #0074d9;
}


/* Progress style */
.progress:after {
  content: ' .';
  font-size: 1.5rem;
  animation: dots 3s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #00F0FF;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #00F0FF,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #00F0FF,
      .5em 0 0 #00F0FF;}}

